/*******************************
         Site Settings
*******************************/

@rootHeight: 100%;

/*-------------------
       Fonts
--------------------*/

@import (css) url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');

/* Guide preview */
@font-face {
 font-family: "Poppins SemiBold";
 src: url("../assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
 font-family: "Poppins Medium";
 src: url("../assets/fonts/Poppins-Medium.ttf");
}
@font-face {
 font-family: "Poppins Regular";
 src: url("../assets/fonts/Poppins-Regular.ttf");
}
@font-face {
 font-family: "Poppins Italic";
 src: url("../assets/fonts/Poppins-Italic.ttf");
}

@pageFont   : 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@headerFont : 'Source Sans Pro', sans-serif;


/*-------------------
      Base Sizes
--------------------*/

/* This is the single variable that controls them all */
@emSize   : 14px;

/* The size of page text  */
@fontSize : @14px;

/*-------------------
    Brand Colors
--------------------*/

@primaryColor : #3C8DBC;

/*--------------
  Page Heading
---------------*/

@headerFontWeight: 500;

@h1 : @36px;
@h2 : @30px;
@h3 : @24px;
@h4 : @18px;
@h5 : @14px;
@h6 : @12px;

/*--------------
   Form Input
---------------*/

/* Line Height Default For Inputs in Browser */
@inputLineHeight        : @20px;
@inputVerticalPadding   : @6px;
@inputHorizontalPadding : @12px;
@inputBorderColor       : #D2D6DE;
@inputPlaceholderColor  : #999;

/*-------------------
    Focused Input
--------------------*/

/* Used on inputs, textarea etc */
@focusedFormBorderColor: #3C8DBC;

/*-------------------
        Page
--------------------*/

@pageBackground : #ECF0F5;
@lineHeight     : @20px;
@textColor      : #333;

/*-------------------
      Paragraph
--------------------*/

@paragraphLineHeight : @lineHeight;

/*-------------------
       Links
--------------------*/

@linkColor           : @primaryColor;
@linkUnderline       : none;
@linkHoverColor      : @primaryColorHover;
@linkHoverUnderline  : underline;

/*-------------------
      Site Colors
--------------------*/

/*---  Colors  ---*/
@blue : @primaryColor;
@green: #00A65A;


/*******************************
           Power-User
*******************************/

/*-------------------
    Emotive Colors
--------------------*/

/* Positive */
@positiveBackgroundColor      : #00A65A;
@positiveBorderColor          : #008d4c;
@positiveHeaderColor          : @white;
@positiveTextColor            : @white;
@positiveHoverBorderColor     : #398439;
@positiveFocusBorderColor     : #255625;
@positiveDownBorderColor      : #398439;
@positiveHoverBackgroundColor : #008d4c;
@positiveFocusBackgroundColor : #449d44;
@positiveDownBackgroundColor  : #008D4C;

/* Negative */
@negativeBackgroundColor      : #DD4B39;
@negativeBorderColor          : #D73925;
@negativeHeaderColor          : @white;
@negativeTextColor            : @white;
@negativeHoverBorderColor     : #AC2925;
@negativeFocusBorderColor     : #761c19;
@negativeDownBorderColor      : #AC2925;
@negativeHoverBackgroundColor : #D73925;
@negativeFocusBackgroundColor : #c9302c;
@negativeDownBackgroundColor  : #D73925;

/* Info */
@infoBackgroundColor          : #00C0EF;
@infoBorderColor              : #0097BC;
@infoHeaderColor              : @white;
@infoTextColor                : @white;
@infoHoverBorderColor         : #00ACD6;
@infoFocusBorderColor         : #1b6d85;
@infoDownBorderColor          : #00ACD6;
@infoHoverBackgroundColor     : #0097BC;
@infoFocusBackgroundColor     : #31b0d5;
@infoDownBackgroundColor      : #0097BC;

/* Warning */
@warningBackgroundColor       : #f39c12;
@warningBorderColor           : #e08e0b;
@warningHeaderColor           : @white;
@warningTextColor             : @white;
@warningHoverBorderColor      : #d58512;
@warningFocusBorderColor      : #985f0d;
@warningDownBorderColor       : #d58512;
@warningHoverBackgroundColor  : #e08e0b;
@warningFocusBackgroundColor  : #ec971f;
@warningDownBackgroundColor   : #d58512;

/*******************************
             States
*******************************/

/*-------------------
        Hover
--------------------*/

/*---  Colors  ---*/
@primaryColorHover : #367FA9;

/*-------------------
        Active
--------------------*/

/*---  Colors  ---*/
@primaryColorActive : #367FA9;

/*-------------------
        Focus
--------------------*/

/*---  Colors  ---*/

@primaryColorFocus : #286090;

/*******************************
        ReactToastify
*******************************/

@toastBoxShadow    : 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
@toastFont         : @pageFont;
@toastPadding      : @15px @15px @15px @15px;
@toastBorderRadius : @3px;

@toastTitleFontWeight : 600;
@toastTitleFontSize   : @18px;
@toastTitleMargin     : 0 0 @10px 0;
@toastTitleLineHeight : @20px;

/*******************************
        ReactDatepicker
*******************************/

@datepickerFont         : @pageFont;
@datepickerBorderRadius : @3px;
@datepickerBoxShadow    : 0 6px 12px rgba(0,0,0,.175);
@datepickerBorder       : @1px solid rgba(0,0,0,.1);
