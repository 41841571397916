/*******************************
         Site Overrides
*******************************/

input::placeholder {
    font-style: @placeholderFontStyle;
}

.ui.icon.input {

    &:focus-within > i.icon:not(.link){
        color: @focusIconColor;
    }

    & > i.icon:not(.link){
        color: @iconColor;
    }
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
    opacity: @inputDisabledOpacity;
}

.ui.disabled.input > input,
.ui.input:not(.disabled) input[disabled] {
  pointer-events: none;
}