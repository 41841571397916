@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Wrapper {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
  cursor: grab;
  padding: 10px;
}
.Wrapper.fadeIn {
  animation: fadeIn 500ms ease;
}
.Wrapper.dragOverlay {
  --scale: 1.05;
  z-index: 999;
}
.ImageItem {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  box-sizing: border-box;
  list-style: none;
  border-radius: calc(4px / var(--scale-x, 1));
  transform-origin: 50% 50%;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
}
.ImageItem.dragging:not(.dragOverlay) {
  opacity: var(--dragging-opacity, 0.5);
  z-index: 0;
}
.ImageItem.dragOverlay {
  cursor: inherit;
  /* box-shadow: 0 0px 6px 2px $focused-outline-color; */
  animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  transform: scale(var(--scale));
  box-shadow: var(--box-shadow-picked-up);
  opacity: 1;
}
