/*******************************
         Site Overrides
*******************************/

.ui.menu {
    .item {
        font-size: @itemTextSize;
    }

    .item > i.icon,
    .icon.item > .icon {
        margin-top: 1px;
    }
}

.ui.secondary.menu {
    .item {
        color: @secondaryItemColor;
        line-height: @secondaryItemLineHeight;

        &.active:hover {
            color: @secondaryActiveItemColor;
            background: @secondaryActiveItemBackground;
        }
    }

    .header {
        text-transform: @secondaryHeaderTextTransform;
        padding: @secondaryHeaderPadding;
        color: @secondaryHeaderTextColor;
        background: @secondaryHeaderBackground;
        font-size: @secondaryHeaderFontSize;
        line-height: @secondaryHeaderLineHeight;
    }
}

.ui.secondary.vertical.menu {
    .item {
        border-left: @secondaryVerticalItemBorderLeft;
        &.active {
            border-color: @secondaryVerticalItemActiveBorderColor;
        }
    }
}

/* Tabular */
.ui.attached.tabular.menu {
    .item {
        padding: @tabularVerticalPadding @tabularHorizontalPadding;
        border-top: @3px solid @tabularBackground;
        margin-top: 1px;
    }

    .active.item {
        border-top: @tabularActiveBorderTop;
        padding: @tabularVerticalPadding @tabularHorizontalPadding;
    }

}

/*--------------
   Pagination
---------------*/

.ui.pagination.menu {
    background-color: @paginationBackground;
    border: @paginationBorder;
    border-radius: 4px;

    .item {
        color: @paginationItemTextColor;
        padding: 13px 16px;
        border-right: @paginationBorder;

        &:last-child {
            border-right: none;
        }

        &:hover {
            background-color: @paginationActiveBackground;
            color: @paginationActiveTextColor;
        }
    }

}

/*---------------------
   Secondary Pointing
-----------------------*/

.ui.secondary.pointing.menu {
    .item {
        font-size: @secondaryPointingItemTextSize;
        color: @secondaryPointingItemColor;
    }
}
