/*******************************
         Site Overrides
*******************************/

.ui.form .field.error {

    .ui.icon.input {

        &:focus-within > i.icon:not(.link){
            color: @inputErrorFocusIconColor;
        }

        & > i.icon:not(.link){
            color: @formErrorIconColor;
        }

    }

    // Deny pointing for error label
    .ui.pointing.label:before {
        content: none;
    }

    .ui.pointing.label {
        margin: @promptMargin;
        font-weight: @promptFontWeight;
        font-size: @promptFontSize;
        padding: @promptPadding;
    }

}

.ui.input input:-webkit-autofill {
    -webkit-text-fill-color: @inputAutoFillColor;
}

/*--------------------
       Disabled
---------------------*/

.ui.form .disabled.fields .field,
.ui.form .disabled.field,
.ui.form .field :disabled {
    pointer-events: none;
    opacity: @formInputDisabledOpacity;
    background: @formInputDisabledBackground;
    cursor: not-allowed;
}

.ui.form .field.checkbox.disabled {
    background-color: #FFF;
}