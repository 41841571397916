.draw-intervention {
   min-width: 45px !important;
   height: 15px;
   border-color: #008d4c;
   background-color: #00A65A;
   content: "Ajouter un secteur d'intervention";
}
.leaflet-measure-static {
   background-color: #00A65A;
   border-color: #008d4c;
}