/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
    line-height: 23px;
}

.ui.search.selection.dropdown > input.search {
    padding: @inputVerticalPadding @inputHorizontalPadding
}

.ui.multiple.dropdown > .label {
    padding: @inputVerticalPadding @inputHorizontalPadding;
    margin-top: @3px;
}

.ui.multiple.search.dropdown > .text {
    line-height: 2;
}

/*--------------------
        Error
----------------------*/

.ui.dropdown.error,
.ui.dropdown.error > .text,
.ui.dropdown.error > .default.text {
  color: @negativeBackgroundColor;
}

.ui.selection.dropdown.error {
    background: @white;
    border-color: @negativeBorderColor;

    .menu.visible {
        border-color: @negativeBorderColor;
    }

}

/*--------------------
    Keyboard Select
----------------------*/

/* Selected Item */
.ui.dropdown.selected,
.ui.dropdown .menu .selected.item {
  background: @selectedBackground;
  color: @selectedColor;
  font-weight: @selectedFontWeight;
}

.ui.dropdown .menu > .item {
    text-decoration: none;
}