/*******************************
         Site Overrides
*******************************/

/* Header */
.ui.segment .header {
    font-size: @headerTextSize;
    line-height: 1;
    margin: 0;
    display: inline-block;
}

.ui.segments {
    background: @background;
}