/*******************************
         Site Overrides
*******************************/

.ui.buttons .button, .ui.buttons .or, .ui.button {
    font-size: @textSize;
}

.ui.button {
    border: 1px solid  @borderColor;

    &:hover {
        border-color: @hoverBorderColor;
    }

    &:active {
        border-color: @downBorderColor;
    }

    &:focus {
        border-color: @focusBorderColor;
    }
}

.ui.primary.button {
    border-color: @primaryBorderColor;

    &:hover {
        border-color: @primaryHoverBorderColor;
        background-color: @primaryHoverBackgroundColor;
    }

    &:active {
        border-color: @primaryDownBorderColor;
        background-color: @primaryDownBackgroundColor;
        box-shadow: @downBoxShadow;
    }

    &:focus {
        border-color: @primaryFocusBorderColor;
        background-color: @primaryFocusBackgroundColor;
    }
}

.ui.negative.button {
    border-color: @negativeBorderColor;
    background-color: @negativeBackgroundColor;

    &:hover {
        border-color: @negativeHoverBorderColor;
        background-color: @negativeHoverBackgroundColor;
    }

    &:active {
        border-color: @negativeDownBorderColor;
        background-color: @negativeDownBackgroundColor;
        box-shadow: @downBoxShadow;
    }

    &:focus {
        border-color: @negativeFocusBorderColor;
        background-color: @negativeFocusBackgroundColor;
    }
}

.ui.positive.button {
    border-color: @positiveBorderColor;
    background-color: @positiveBackgroundColor;

    &:hover {
        border-color: @positiveHoverBorderColor;
        background-color: @positiveHoverBackgroundColor;
    }

    &:active {
        border-color: @positiveDownBorderColor;
        background-color: @positiveDownBackgroundColor;
        box-shadow: @downBoxShadow;
    }

    &:focus {
        border-color: @positiveFocusBorderColor;
        background-color: @positiveFocusBackgroundColor;
    }
}

.ui.warning.button {
    border-color: @warningBorderColor;
    background-color: @warningBackgroundColor;
    color: #FFF;

    &:hover {
        border-color: @warningHoverBorderColor;
        background-color: @warningHoverBackgroundColor;
    }

    &:active {
        border-color: @warningDownBorderColor;
        background-color: @warningDownBackgroundColor;
        box-shadow: @downBoxShadow;
    }

    &:focus {
        border-color: @warningFocusBorderColor;
        background-color: @warningFocusBackgroundColor;
    }
}

.ui.info.button {
    border-color: @infoBorderColor;
    background-color: @infoBackgroundColor;
    color: #FFF;

    &:hover {
        border-color: @infoHoverBorderColor;
        background-color: @infoHoverBackgroundColor;
    }

    &:active {
        border-color: @infoDownBorderColor;
        background-color: @infoDownBackgroundColor;
        box-shadow: @downBoxShadow;
    }

    &:focus {
        border-color: @infoFocusBorderColor;
        background-color: @infoFocusBackgroundColor;
    }
}

.ui.basic, .ui.basic:hover {
    border: none;
}

/*******************************
            Groups
*******************************/

.ui.buttons .button {
    border: none;
}

/* Basic */
.ui.basic.red.button:hover,
.ui.basic.orange.button:hover,
.ui.basic.yellow.button:hover,
.ui.basic.olive.button:hover,
.ui.basic.green.button:hover,
.ui.basic.teal.button:hover,
.ui.basic.blue.button:hover,
.ui.basic.violet.button:hover,
.ui.basic.purple.button:hover,
.ui.basic.pink.button:hover,
.ui.basic.brown.button:hover,
.ui.basic.grey.button:hover,
.ui.basic.black.button:hover {
    background: @basicHoverBackground !important;
}