/*******************************
         Site Overrides
*******************************/

.ui.sortable.table thead th:after {
    display: inline;
    content: @sortableIcon;
}

.ui.sortable.table thead th.not.sortable {

    cursor: default;

    &:after {
        display: none;
    }
}